<template>
  <v-dialog fullscreen v-model="modal.display" persistent>
    <v-card color="fourth">
      <v-toolbar elevation="0" rounded="0" color="white">
        <v-btn class="mr-2" icon @click="modal.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.campaign.simple.detail.title")
          }}</span>
        </span>
      </v-toolbar>
      <v-card-text class="pt-10">
        <div class="d-flex align-center justify-center">
          <div style="width: 1000px">
            <v-card class="mb-4 rounded-md">
              <div
                class="d-flex align-center justify-start px-4 py-3 flex-wrap"
              >
                <v-btn
                  depressed
                  @click="refreshItems()"
                  :loading="loading.refresh"
                  class="mr-2"
                >
                  <v-icon left>mdi-refresh</v-icon>
                  {{ $t("btn.refresh") }}
                </v-btn>
                <div class="ml-auto">
                  <v-text-field
                    style="width: 220px"
                    dense
                    append-icon="mdi-magnify"
                    :label="$t('bulksms.campaign.fields.numbers.search')"
                    class="font-weight-bold"
                    @click:append="filter()"
                    @keyup.enter.prevent="filter"
                    v-model="filters.phone"
                    clearable
                  ></v-text-field>
                </div>
              </div>
            </v-card>
            <v-card>
              <v-data-table
                class="elevation-2 datatable"
                :loading="loading.list"
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :footer-props="footerProps"
                :items-per-page="itemPerPage"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :server-items-length="meta.total"
              >
                <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
                  <tbody>
                    <template v-for="item in items">
                      <tr :key="`item-${item.id}`">
                        <td style="min-width: 130px">
                          <span>{{
                            $moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")
                          }}</span>
                        </td>
                        <td>{{ item.sender }}</td>
                        <td>{{ item.da }}</td>
                        <td>
                          <v-chip
                            color="success"
                            class="table-chips text-body-2"
                            v-if="item.status == '200'"
                            >{{
                              $t("bulksms.campaign.fields.status.success")
                            }}</v-chip
                          >
                          <v-chip
                            color="error"
                            class="table-chips text-body-2"
                            v-else
                            >{{
                              $t(
                                "bulksms.campaign.fields.status." + item.status
                              )
                            }}</v-chip
                          >
                        </td>
                        <td>
                          <v-chip color="fourth" class="table-chips text-body-2"
                            ><span>{{ $utils.pad(item.cost, 2) }}</span>
                            SMS</v-chip
                          >
                        </td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="
                                  expandedIndex =
                                    expandedIndex === item.id ? -1 : item.id
                                "
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="ml-5"
                              >
                                <v-icon color="grey darken-3">{{
                                  expandedIndex === item.id
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              $t("bulksms.campaign.btn.message")
                            }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <v-slide-y-transition
                        duration="200"
                        type="animtion"
                        :key="`expand-${item.id}`"
                        :mode="expandedIndex === item.id ? 'out-in' : 'in-out'"
                      >
                        <tr v-if="expandedIndex === item.id">
                          <td :colspan="headers.length">{{ item.content }}</td>
                        </tr>
                      </v-slide-y-transition>
                    </template>
                  </tbody>
                </template>
                <template v-slot:expand="{ item }">
                  <v-card flat>
                    <v-card-text>{{ item.content }}</v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  props: {
    modal: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    path: null,
    filters: {
      phone: null,
    },
    expandedIndex: -1,
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("bulksms.campaign.fields.created.title"),
          value: "createAt",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("bulksms.campaign.fields.sender.title2"),
          value: "sender",
          align: "start",
          sortable: false,
          width: 120,
        },
        {
          text: this.$t("bulksms.campaign.fields.numbers.title2"),
          value: "da",
          align: "start",
          sortable: false,
          width: 100,
        },
        {
          text: this.$t("bulksms.campaign.fields.status.title"),
          value: "status",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.cost.title"),
          value: "cost",
          align: "start",
          sortable: false,
          width: 70,
        },
        {
          text: "",
          align: "start",
          width: 50,
          sortable: false,
        }
      ];
    },
    getAdditionalParams() {
      return {
        filter: `pushFile.id||$eq||${this.campaign.id}`,
      };
    },
    filter() {
      if (this.filters.phone) {
        this.applyFilter({
          da: {
            operator: "$eq",
            value: this.filters.phone,
          },
        });
      } else {
        this.applyFilter({});
      }
    },
  },
  watch: {
    "modal.display"(val) {
      if (!val) {
        this.items = [];
      }
      if (val) {
        this.$nextTick(() => {
          this.path = `/api/providers/bulksms/pushes/file-messages`;
          this.getItems();
        });
      }
    },
  },
};
</script>