<template>
  <v-dialog v-model="dialog.display" scrollable max-width="500px">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.campaign.simple.display.title")
          }}</span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="!dialog.loading" class="px-3">
        <v-container fluid grid-list-md v-if="!_.isEmpty(campaign)">
          <v-row
            v-if="
              (campaign.status === 'ERROR' || campaign.status === 'EXIT') &&
              campaign.fileStatus
            "
          >
            <v-col cols="12">
              <v-alert type="danger" v-if="campaign.status === 'ERROR'"
                ><v-icon left>mdi-alert-circle-outline</v-icon
                ><span class="text-body-1">
                  {{
                    $t(
                      `bulksms.campaign.fields.errorReason.${campaign.fileStatus}`
                    )
                  }}</span
                ></v-alert
              >
              <v-alert type="warning" v-else>
                <v-icon left>mdi-alert-outline</v-icon
                ><span class="text-body-1">
                  {{
                    $t(
                      `bulksms.campaign.fields.errorReason.${campaign.fileStatus}`
                    )
                  }}</span
                >
              </v-alert>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-calendar-blank-outline</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.created.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span>{{
                    $moment(campaign.createdAt).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="4">
              <div>
                <v-icon class="mr-2 pb-1">mdi-file-document-outline</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.uri.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span :title="campaign.uri">{{
                    $utils.truncate(campaign.uri, 50)
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-radiobox-blank</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.status.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="secondary"
                  class="table-chips"
                  v-if="campaign.status === 'NEW'"
                  >{{ $t("bulksms.campaign.fields.status.new") }}</v-chip
                >
                <v-chip
                  color="primary"
                  class="table-chips"
                  v-else-if="campaign.status === 'RUNNING'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.init")
                  }}</span>
                </v-chip>
                <v-chip
                  color="error"
                  class="table-chips"
                  v-else-if="campaign.status === 'ERROR'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.error")
                  }}</span>
                </v-chip>
                <v-chip
                  color="amber darken-4"
                  class="table-chips"
                  v-else-if="campaign.status === 'EXIT'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.exit")
                  }}</span>
                </v-chip>
                <v-chip
                  color="success"
                  class="table-chips"
                  v-else-if="campaign.status === 'SUCCESS'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.success")
                  }}</span>
                </v-chip>
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold"
                  v-else
                >
                  <span class="white--text">-</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-file-percent-outline</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.size.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span>{{ $utils.formatSize(campaign.size * 1024) }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-text-box-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.lines.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span class="text-body-2">{{
                    $utils.pad(campaign.lines, 2)
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-text-box-check-outline</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.submitted.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span v-if="campaign.submitted">{{
                    $utils.pad(campaign.submitted, 2)
                  }}</span>
                  <span v-else>0</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-text-box-remove-outline</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.errorLine.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips font-weight-bold mr-0"
                >
                  <span v-if="campaign.lastTreated">{{
                    $utils.pad(campaign.lastTreated - campaign.submitted, 2)
                  }}</span>
                  <span v-else>0</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center mt-5">
          <v-progress-circular
            indeterminate
            color="secondary"
            width="3"
            size="50"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          loading: true,
        };
      },
    },
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
};
</script>