<template>
  <div>
    <v-card class="mb-4 rounded-md">
      <div class="d-flex align-center justify-start px-4 pt-3 pb-2 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilter()" class="mb-2 mr-2" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div  :class="{'mr-auto': $vuetify.breakpoint.xsOnly, 'ml-auto': $vuetify.breakpoint.smAndUp }" class="mb-2">
          <v-btn depressed color="primary" :to="{ name: 'bulksms-campaign-file' }">
            <v-icon left>mdi-email-plus-outline</v-icon>
            <span>{{ $t("bulksms.campaign.file.btn.create") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card class="rounded-md mb-4">
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot:default="{ hover }">
                <tr>
                  <td style="min-width: 150px">
                    <span>{{
                      $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td :title="item.uri">
                    <span :title="item.uri">{{
                      $utils.truncate(item.uri, 50)
                    }}</span>
                  </td>
                  <td>
                    <v-chip
                      color="secondary"
                      class="table-chips text-body-2"
                      v-if="item.status === 'NEW'"
                      >{{ $t("bulksms.campaign.fields.status.new") }}</v-chip
                    >
                    <v-chip
                      color="primary"
                      class="table-chips text-body-2"
                      v-else-if="item.status === 'RUNNING'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.init")
                      }}</span>
                    </v-chip>
                    <v-chip
                      color="error"
                      class="table-chips text-body-2"
                      v-else-if="item.status === 'ERROR'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.error")
                      }}</span>
                    </v-chip>
                    <v-chip
                      color="amber darken-4"
                      class="table-chips text-body-2"
                      v-else-if="item.status === 'EXIT'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.exit")
                      }}</span>
                    </v-chip>
                    <v-chip
                      color="success"
                      class="table-chips text-body-2"
                      v-else-if="item.status === 'SUCCESS'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.success")
                      }}</span>
                    </v-chip>
                    <v-chip
                      color="primary"
                      class="table-chips text-body-2"
                      v-else
                    >
                      <span class="white--text">-</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips text-body-2">
                      <span>{{ $utils.formatSize(item.size * 1024) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips text-body-2">
                      <span>{{ $utils.pad(item.lines, 2) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips text-body-2">
                      <span v-if="item.submitted">{{
                        $utils.pad(item.submitted, 2)
                      }}</span>
                      <span v-else>0</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips text-body-2">
                      <span v-if="item.lastTreated">{{
                        $utils.pad(item.lastTreated - item.submitted, 2)
                      }}</span>
                      <span v-else>0</span>
                    </v-chip>
                  </td>
                  <td style="min-width: 100px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getCampaign(item.id)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.display.description") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getCampaignDetails(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-3"
                        >
                          <v-icon color="primary">mdi-text-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("bulksms.campaign.file.btn.detail") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <campaign-file-view
        :dialog="dialog.view"
        :campaign="campaign.view"
      ></campaign-file-view>
      <campaign-file-detail
        :modal="dialog.detail"
        :campaign="campaign.detail"
      ></campaign-file-detail>
      <campaign-file-filter
      :dialog="dialog.filter"
      @filter="applyFilter"
    ></campaign-file-filter>
    </v-card>
  </div>
</template>

<script>
import CampaignFileView from "./file-view";
import CampaignFileFilter from "./file-filter";
import CampaignFileDetail from "./file-details";
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  metaInfo() {
    return {
      title: this.$t("bulksms.campaign.file.list.title"),
    };
  },
  data: () => ({
    path: "/api/providers/bulksms/pushes/file",
    dialog: {
      view: {
        display: false,
        loading: false,
      },
      filter: {
        display: false,
      },
      detail: {
        display: false,
      },
    },
    campaign: {
      view: {},
      detail: {},
    },
  }),
  methods: {
    setHeaders() {
      const headers = [
        {
          text: this.$t("bulksms.campaign.fields.created.title"),
          value: "createdAt",
          align: "start",
          sortable: true,
          width: 130,
        },
        {
          text: this.$t("bulksms.campaign.fields.uri.title"),
          value: "uri",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.campaign.fields.status.title"),
          value: "status",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.size.title"),
          value: "size",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.lines.title"),
          value: "lines",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.submitted.title"),
          value: "submitted",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.errorLine.title"),
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: "",
          align: "start",
          width: 100,
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    async getCampaign(id) {
      // reset push content
      this.campaign.display = {};

      this.dialog.view.loading = true;
      this.dialog.view.display = true;
      try {
        const response = await this.request({
          url: `/api/providers/bulksms/pushes/file/${id}`,
        });
        this.campaign.view = response.data;
      } catch (error) {
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const data = error.response.data;
          if (data.details.code === 404) {
            message = "bulksms.campaign.error.not_found";
          }
        }
        this.notify({ message: this.$t(message) });
      }
      this.dialog.view.loading = false;
    },
    getCampaignDetails(campaign) {
      this.campaign.detail = campaign;
      this.dialog.detail.display = true;
    },
    getFilter() {
      this.dialog.filter.display = true;
    }
  },
  components: {
    CampaignFileView,
    CampaignFileDetail,
    CampaignFileFilter
  },
};
</script>