<template>
  <div>
    <campaign-file-list></campaign-file-list>
  </div>
</template>

<script>
import CampaignFileList from './../../../components/bulksms/campaigns/file-list';
export default {
  data: () => ({
  }),
  components: {
    CampaignFileList
  }
}
</script>